import Cookies from "js-cookie";
import { Auth } from 'aws-amplify';

const installationIdCookieName = "installationIdWebPortal"

const {fetch: origFetch} = window;
window.fetch = async (...args) => {
  const isProtectedResource = args.length >= 2 && args[1].hasOwnProperty("headers") && args[1].headers.hasOwnProperty("Authorization")
  const legacySessionToken = sessionStorage.getItem("legacySessionToken")
  if (isProtectedResource) {
    if (legacySessionToken) {
      delete args[1].headers.Authorization // remove the preferred auth method
      args[1].headers["Nexkey-Session-Token"] = legacySessionToken // and pass sessionToken
    }
    else {
      try {
        const session = await Auth.currentSession()
        const accessToken = session.getAccessToken().getJwtToken()
        if (accessToken) {
          args[1].headers.Authorization = `Bearer ${accessToken}`
        }
      } catch(error) {
        console.log("error", error)
        window.location.reload();
        throw new Error("Your session has expired");
      }
    }
  }
  const response = await origFetch(...args);

  if (legacySessionToken && response.status === 400 && response.url.includes("/functions/me")) {
    sessionStorage.removeItem("legacySessionToken")
    Cookies.remove("sessionToken")
    window.location.reload();
  }

  return response
};

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line
    return v.toString(16);
  });
}

const getInstallationId = () => {
  const installationId = uuidv4()
  Cookies.set(installationIdCookieName, installationId, { expires: 365 });
  return installationId
}

const api_number = process.env.REACT_APP_PARSE_API_VERSION
const endpoint_uri_base = process.env.REACT_APP_PARSE_API_URI
const app_id = process.env.REACT_APP_PARSE_APP_ID
const installation_id = Cookies.get("installationId") || getInstallationId()
const post_body_base = {}

const headers = (session_token) => {
  if (session_token) {
    return {
       // session_token is boolean at this point. actual value is applied in window.fetch
      "Access-Control-Allow-Origin":"*",
       "Authorization": `Bearer ${session_token}`,
      "Nexkey-Application-Id": `${app_id}`,
      "Nexkey-Api-Version": `${api_number}`,
      "Nexkey-Installation-Id": `${installation_id}`,
      "Accept": "application/json, text/plain, */*",
      "Content-Type": "application/json;charset=UTF-8"
    }
  }
  else {
    return {
      "Access-Control-Allow-Origin":"*",
      "Nexkey-Application-Id": `${app_id}`,
      "Nexkey-Api-Version": `${api_number}`,
      "Nexkey-Installation-Id": `${installation_id}`,
      "Accept": "application/json, text/plain, */*",
      "Content-Type": "application/json;charset=UTF-8"
    }
  }
}

const handleError = data => {
  if (data.code === 209) { // Parse.Error.INVALID_SESSION_TOKEN === 209
    localStorage.setItem("expiredSession", true);
    window.location.reload(true);
  }
  throw new Error(data.error)
}

const removePrivateProperties = (key, value) => {
  if (key !== '__type' && key.indexOf('_') === 0) return undefined;
  else return value;
}

export const fetchKeys = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/getAllUserKeys`
  postBody = {...postBody, ...payload}

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result || !data.result.keys) {
        handleError(data)
      }
      data.result.keys.sort((a, b) => (a.name > b.name) ? 1 : -1)

      // returning data.keys iff getDetailedGroupInfo is not set or false 
      return (payload && payload.hasOwnProperty("getDetailedGroupInfo") && payload.getDetailedGroupInfo) ? data.result : data.result.keys
    })
}

export const fetchKeyAccessList = (currentUser, payload) => {
  let postBody = {...post_body_base}
  postBody = {...postBody, ...payload}
  const endpoint = `${endpoint_uri_base}/functions/getAllUsersForLockFlat`

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result;
    })
}

export const fetchKeyAuditLog = (currentUser, payload) => {
  let postBody = {
    ...post_body_base, 
    ...{
      handleWording: true,
      pageSize: 20,
      pageNumber: 0
    },
    ...payload
  }
  const endpoint = `${endpoint_uri_base}/functions/getLockAuditLog`

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result; 
    })
}

export const fetchMultiKeyAuditLog = (currentUser, lockIds, pageSize, pageNumber, beginTime, endTime, actionGroup, sourceUserIds) => {
  let postBody = {...post_body_base}
  postBody.lockIds = lockIds
  postBody.handleWording = true
  postBody.pageSize = pageSize ? pageSize : 20
  postBody.pageNumber = pageNumber ? pageNumber : 0
  if (beginTime) {
    postBody.beginTime = beginTime
  }
  if (endTime) {
    postBody.endTime = endTime  
  }
  if (actionGroup) {
    postBody.actionGroup = actionGroup
  }
  postBody.sourceUserIds = sourceUserIds
  const endpoint = `${endpoint_uri_base}/functions/getMultiLockAuditLog`

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result; 
    })
}

export const fetchUserAuditLog = (currentUser, payload) => {
  let postBody = {
    ...post_body_base, 
    ...{
      handleWording: true,
      pageSize: 20,
      pageNumber: 0
    },
    ...payload
  }
  const endpoint = `${endpoint_uri_base}/functions/getUserAuditLog`

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result; 
    })
}


export const fetchGroupAuditLog = (currentUser, payload) => {
  let postBody = {
    ...post_body_base, 
    ...{
      handleWording: true,
      pageSize: 20,
      pageNumber: 0
    },
    ...payload
  }
  // getGroupAuditLog uses startTime instead of beginTime
  if (postBody.hasOwnProperty("beginTime")) {
    postBody.startTime = postBody.beginTime
    delete postBody.beginTime
  }
  const endpoint = `${endpoint_uri_base}/functions/getGroupAuditLog`

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result; 
    })
}

export const fetchContactList = (currentUser) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/getContactList`

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const shareKeys = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/shareKeys`
  postBody = {...postBody, ...payload}

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const revokeUserKey = (currentUser, lockId, revokedUserPhoneNumber, pendingEmail) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/revokeUserKey`
  postBody.lockId = lockId
  postBody.revokedUserPhoneNumber = revokedUserPhoneNumber ? revokedUserPhoneNumber : null
  postBody.pendingEmail = pendingEmail ? pendingEmail : null

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const revokeKeys = (currentUser, lockIds, targetUserIds, override) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/revokeKeys`
  postBody.lockIds = lockIds
  postBody.targetUserIds = targetUserIds
  postBody.override = override

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const removeContacts = (currentUser, targetUserIds) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/removeContacts`
  postBody.targetUserIds = targetUserIds

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const migrateUser = (userIdentifier, password) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/migrateUser`
  postBody.userIdentifier = userIdentifier
  postBody.password = password
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const logInUser = (userIdentifier, password) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/logInUser`
  postBody.userIdentifier = userIdentifier
  postBody.password = password
  postBody.installationId = installation_id
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const logout = (sessionToken) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/logout`

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(sessionToken),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        throw new Error(data.error)
      }

      return data.result
    })
}

export const inviteUser = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/inviteUser`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const inviteUsers = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/inviteUsers`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const me = (newPassword) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/me`
  if (newPassword)
    postBody.new_password = newPassword

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        throw new Error(data.error)
      }

      return data.result
    })
}

export const changePassword = (payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/changePassword`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const signUpUser = (payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/signUpUser`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const verify = (currentUser, type, code) => {
  let postBody = {...post_body_base}
  let endpoint
  if (type === 'email') {
    endpoint = `${endpoint_uri_base}/functions/verifyEmail`
    postBody.emailCode = code
  }
  else if (type === 'primaryPhone') {
    endpoint = `${endpoint_uri_base}/functions/verifyPhone`
    postBody.smsCode = code
  }
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const resendVerification = (currentUser, type, newValue) => {
  let postBody = {...post_body_base}
  let endpoint
  if (type === 'email') {
    endpoint = `${endpoint_uri_base}/functions/resendEmailVerification`
    postBody.email = newValue ? newValue : currentUser.email
  }
  else if (type === 'primaryPhone') {
    endpoint = `${endpoint_uri_base}/functions/resendPhoneVerification`
    postBody.phoneNumber = newValue ? newValue : currentUser.primaryPhone
  }
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const fetchConfig = () => {
  const endpoint = `${endpoint_uri_base}/api/config`
 
  return fetch(endpoint, {
      "headers": headers()
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.params) {
        handleError(data)
      }

      return data.params
    })
}

export const fetchLatestTermsOfService = () => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/fetchLatestTermsOfService`
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(),
      "body": JSON.stringify(postBody)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const acceptTermsOfService = (version) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/acceptTermsOfService`
  postBody.version = version
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const deactivateUser = (daysUntilDeactivation) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/deactivateUser`
  if (daysUntilDeactivation || daysUntilDeactivation === 0)
    postBody.daysUntilDeactivation = daysUntilDeactivation
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const saveLockDetails = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/saveLockDetails`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const forgotPassword = (userIdentifier) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/forgotPassword`
  postBody.userIdentifier = userIdentifier

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const getAllUnlocksForUser = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/getAllUnlocksForUser`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const getUnlockStats = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/getUnlockStats`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const getPeopleStats = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/getPeopleStats`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const getTopUnlockerStats = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/getTopUnlockerStats`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const getPendingUserStats = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/getPendingUserStats`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const getGlobalUnlocksCount = (currentUser) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/getGlobalUnlocksCount`
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const getNotifications = (currentUser, payload) => {
  let postBody = {
    ...post_body_base, 
    ...{
      pageSize: 20,
      pageNumber: 0
    },
    ...payload
  }
  const endpoint = `${endpoint_uri_base}/functions/getNotifications`

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result; 
    })
}

export const getUserGroups = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/getUserGroups`
  postBody = {...postBody, ...payload}

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }
      data.result.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)

      return data.result
    })
}

export const createUserGroup = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/createUserGroup`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

// https://nexkey.slack.com/archives/CNUFWH7QQ/p1585692353000500

export const addToGroup = (currentUser, _function, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/${_function}`
  postBody = {...postBody, ...payload}

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

// accepts override 
export const addMembersToGroup = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/addMembersToGroup`
  postBody = {...postBody, ...payload}

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const removeFromGroup = (currentUser, _function, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/${_function}`
  postBody = {...postBody, ...payload}

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

// does not accept override 
export const removeMembersFromGroup = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/removeMembersFromGroup`
  postBody = {...postBody, ...payload}

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

// does not accept override 
export const removeLocksFromGroup = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/removeLocksFromGroup`
  postBody = {...postBody, ...payload}

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const addLocksToGroup = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/addLocksToGroup`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const modifyGroupDetails = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/modifyGroupDetails`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const deleteUserGroups = (currentUser, groupIds) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/deleteUserGroups`
  postBody.groupIds = groupIds

  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const modifyGroupSchedule = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/modifyGroupSchedule`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const remoteUnlock = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/remoteUnlock`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const remoteLock = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/remoteLock`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const updateLockStatusConflict = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/updateLockStatusConflict`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const toggleBusinessHoursWithObjectId = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/toggleBusinessHoursWithObjectId`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const getBusinessHoursForLockId = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/getBusinessHoursForLockId`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const deleteBusinessHoursWithObjectId = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/deleteBusinessHoursWithObjectId`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const setBusinessHoursForLockId = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/setBusinessHoursForLockId`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const getBlackoutDates = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/getBlackoutDates`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: {dates: []}}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const removeBlackoutDates = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/removeBlackoutDates`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const addBlackoutDates = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/addBlackoutDates`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const updateUserPhoneNumber = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/updateUserPhoneNumber`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const verifyPassword = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/verifyPassword`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const updateUserPassword = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/updateUserPassword`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const resendUpdatedPhoneVerification = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/resendUpdatedPhoneVerification`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const verifyUpdatedPhone = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/verifyUpdatedPhone`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const updateUserEmail = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/updateUserEmail`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const resendUpdatedEmailVerification = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/resendUpdatedEmailVerification`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const verifyUpdatedEmail = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/verifyUpdatedEmail`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const getPendingContactInfo = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/getPendingContactInfo`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const fetchUserNotificationEnrollmentGroups = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/fetchUserNotificationEnrollmentGroups`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const createNotificationEnrollmentGroup = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/createNotificationEnrollmentGroup`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const updateNotificationEnrollmentGroup = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/updateNotificationEnrollmentGroup`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const deleteNotificationEnrollmentGroup = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/deleteNotificationEnrollmentGroup`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const sendMessageToUsers = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/sendMessageToUsers`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const sendMessageToKeyHolders = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/sendMessageToKeyHolders`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const sendMessageToGroups = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/sendMessageToGroups`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const createTriggeredMessage = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/createTriggeredMessage`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const updateTriggeredMessage = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/updateTriggeredMessage`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const cancelTriggeredMessage = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/cancelTriggeredMessage`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const restoreTriggeredMessage = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/restoreTriggeredMessage`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const fetchTriggeredMessages = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/fetchTriggeredMessages`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const fetchAllTriggeredMessages = (currentUser, payload) => {
  const endpoint = `${endpoint_uri_base}/functions/fetchAllTriggeredMessages`
  let postBody = {
    ...post_body_base, 
    ...{
      pageSize: 100,
      pageNumber: 0
    },
    ...payload
  }
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const getTriggeredMessagesSent = (currentUser, payload) => {
  const endpoint = `${endpoint_uri_base}/functions/getTriggeredMessagesSent`
  let postBody = {
    ...post_body_base, 
    ...{
      pageSize: 100,
      pageNumber: 0
    },
    ...payload
  }
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

// getInstantMessages
export const fetchInstantMessagesSent = (currentUser, payload) => {
  const endpoint = `${endpoint_uri_base}/functions/getInstantMessagesSent`
  let postBody = {
    ...post_body_base, 
    ...{
      pageSize: 100,
      pageNumber: 0
    },
    ...payload
  }
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: true}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const getDashboards = (currentUser) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/getDashboards`
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (JSON.stringify(data) === '{}') {
        data = {result: []}
      }
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const createDashboard = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/createDashboard`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const deleteDashboards = (currentUser, dashboardIds) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/deleteDashboards`
  postBody.dashboardIds = dashboardIds
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const updateDashboardItems = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/updateDashboardItems`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const updateDashboardDetails = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/updateDashboardDetails`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}

export const updateUserAdvancedFeatures = (currentUser, payload) => {
  let postBody = {...post_body_base}
  const endpoint = `${endpoint_uri_base}/functions/updateUserAdvancedFeatures`
  postBody = {...postBody, ...payload}
 
  return fetch(endpoint, {
      "method": "POST",
      "headers": headers(true),
      "body": JSON.stringify(postBody, removePrivateProperties)
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data.result) {
        handleError(data)
      }

      return data.result
    })
}
