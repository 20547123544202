// // TODO: refactor to use all cammel case enum keys

// // polyfill for older NodeJS servers
// if (!Object.entries)
//   Object.entries = function(obj) {
//     var ownProps = Object.keys(obj),
//       i = ownProps.length,
//       resArray = new Array(i); // preallocate the Array
//     while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

//     return resArray;
//   };

// module.exports.returnAllEnumValues = obj => {
//   let enums = [];
//   for (let [k, v] of Object.entries(obj)) {
//     if (typeof v === 'number') enums.push(v);
//   }
//   return enums;
// };

// module.exports.returnKeyNameForEnumValue = (enumsObject, enumValue) => {
//   let keyName;
//   for (let [k, v] of Object.entries(enumsObject)) {
//     if (v === enumValue) keyName = k;
//   }
//   return keyName;
// };

export const Actions = {
  sendKeyActionName: 1, // "Send Key"
  sendSmsKeyActionName: 2, // "Send SMS Key"
  revokedKeyActionName: 3, // "Revoke Key"
  setMinimumSecurityLevelActionName: 4, // "Set Minimum Security Level"
  accountCreatedActionName: 5, // "Account Created"
  invitedUserActionName: 6, // "Invited User"
  keyTimeRestrictionModified: 7, // "Time Restriction Modified" [unsupported]
  ownershipTransferred: 8, // "Lock Ownership Transferred"
  unlockActionName: 9, // "Unlocked"
  remoteUnlockActionName: 10, // "Remotely Unlocked"
  smsUnlockActionName: 11, // "Unlocked via SMS"
  lowBatteryActionName: 12, // "Low Battery" [unsupported]
  criticallyLowBatteryActionName: 13, // "Critically Low Battery" [unsupported]
  claimLockActionName: 14, // "Claimed Lock"
  keyExpiredActionName: 15, // "Key Expired"
  addLockToHubAction: 16, // "Lock Added to Hub"
  removeLockFromHubAction: 17, // "Lock Removed from Hub" [unsupported]
  editedLockDetailsAction: 18, // "Edited Lock Details"
  addedUserToApiKeyAction: 19, // "Added User to API Key"
  removedUserFromApiKeyAction: 20, // "Removed User from API Key"
  addedLockToApiKeyAction: 21, // "Removed Lock from API Key"
  removedLockFromApiKeyAction: 22, // "Removed Lock from API Key"
  transferredOwnershipOfApiKeyAction: 23, // "API Key Ownership Transferred" [unsupported]
  businessHoursUnlockActionName: 24, // "Remotely Unlocked via BusinessHours entry"
  lockRelockedActionName: 25, // "Lock was Relocked"
  lockRemotelyRelockedActionName: 26, // "Lock was remotely Relocked"
  userCreatedBusinessHoursActionName: 27, // "User Created Business Hours Schedule"
  userUpdatedBusinessHoursActionName: 28, // "User Updated Business Hours Schedule"
  userDeletedBusinessHoursActionName: 29, // "User Deleted Business Hours Schedule"
  localUnlockResumingBusinessHoursActionName: 30, // "Unlocked Locally Resuming Business Hours"
  remoteUnlockResumingBusinessHoursActionName: 31, // "Unlocked Remotely Resuming Business Hours"
  unlockedUntilActionName: 32, // "User Unlocked Lock Until <datetime>"
  userDisabledBusinessHoursActionName: 33, // "User Disable Business Hours Schedule"
  userEnabledBusinessHoursActionName: 34, // "User Enable Business Hours Schedule"
  modifiedScheduledKeyTimeActionName: 35,  // "Modified key schedule"
  groupCreatedActionName: 36, // Group created
  groupDeletedActionName: 37, // Group deleted
  adminAddedToGroupActionName: 38, // "Added Admin to Group"
  adminRemovedFromGroupActionName: 39, // "Removed Admin from Group"
  userAddedToGroupActionName: 40, // "Added User to Group"
  userRemovedFromGroupActionName: 41, // "Removed User from Group"
  lockAddedToGroupActionName: 42, // "Lock Added to Group"
  lockRemovedFromGroupActionName: 43, // "Lock Removed from Group"
  modifiedGroupScheduledKeyTimeActionName: 44,  // "Modified group key schedule"
  modifiedGroupDetailsActionName: 45,  // "Modified group details"
  groupOwnerAddedActionName: 46,  // "Group owner added"
  groupOwnerRemovedActionName: 47,  // "Group owner removed"
  blackoutScheduleModifiedActionName: 48, // "Blackout schedule modified"
  blackoutScheduleAddedActionName: 49,    // "Blackout schedule added"
  blackoutScheduleRemovedActionName: 50,   // "Blackout schedule removed"
  userNameChangedActionName: 51, // "User name has been changed"
  userPhoneNumberChangedActionName: 52, // "User phone number changed"
  userEmailChangedActionName: 53, // "User email changed"
  customMessageSentActionName: 54 // "Custom message sent to key holders from a Lock owner or admin"
};
// module.exports.Actions.actionGroups = {
//   all: module.exports.returnAllEnumValues(module.exports.Actions),
//   admin: [
//     1, // "Send Key"
//     2, // "Send SMS Key"
//     3, // "Revoke Key"
//     4, // "Set Minimum Security Level"
//     8, // "Lock Ownership Transferred"
//     15, // "Key Expired"
//     16, // "Lock Added to Hub"
//     17, // "Lock Removed from Hub"
//     18, // "Edited Lock Details"
//     19, // "Added User to API Key"
//     20, // "Removed User from API Key"
//     21, // "Removed Lock from API Key"
//     22, // "Removed Lock from API Key"
//     23, // "API Key Ownership Transferred"
//   ],
//   apiActions: [
//     1, // "Send Key"
//     2, // "Send SMS Key"
//     3, // "Revoke Key"
//   ],
//   unlocks: [
//     9, // "Unlocked"
//     10, // "Remotely Unlocked"
//     11, // "Unlocked via SMS"
//     24, // "Remotely Unlocked via BusinessHours entry"
//     25, // "Lock was Relocked"
//   ],
//   apiKey: [
//     19, // "Added User to API Key"
//     20, // "Removed User from API Key"
//     21, // "Removed Lock from API Key"
//     22, // "Removed Lock from API Key"
//     23, // "API Key Ownership Transferred"
//   ],
// };

// module.exports.BusinessHoursType = {
//   serverInitiated: 0,
//   userInitiated: 1,
// };

// module.exports.HubStatus = {
//   REGISTERED: -1, // we don't need this now that HubStatus is applied to HubRequest
//   DISCONNECTED: 0, // back to listening
//   INITIATED: 1, // received by server
//   RECEIVED: 2, // received by hub
//   AUTHENTICATING: 3, // server to hub authentication
//   SEARCHING: 4, // hub searching for lock
//   CONNECTED: 5, // phone to lock authentication
//   UNLOCKED: 6, // hub has unlocked lock
//   RELOCKED: 7, // lock has relocked
// };

export const HubRequestStatus = {
  ////////////////////////////////
  // STATUS CODES FOR UNLOCKING //
  ////////////////////////////////

  IDLE: 0, 		// INITIAL STATE
  INITIATED: 10,		// SERVER received remote UNLOCK request; SERVER creating new HubRequest...
  RECEIVED: 20,		// HUB received remote unlock request; HUB starting server-hub authentication...
  AUTHD_BY_SERVER: 30,	// SERVER-HUB auth complete; HUB starting BLE scan for lock...
  LOCK_FOUND: 40,		// HUB found lock; HUB connecting to lock...
  CONNECTED: 50,		// HUB connected to lock; HUB starting hub-lock authentication...
  AUTHD_BY_LOCK: 60,	// HUB-LOCK auth complete; HUB sending unlock command to lock...
  UNLOCKED: 70,		// HUB received unlocked status from lock; HUB waiting to receive relocked status from lock...
  UNLOCKED_ALREADY: 71,		// HUB received unlocked status from lock; LOCK is already unlocked...
  RELOCKED: 80,		// HUB received relocked status from lock; HUB cleaning up resources and returning to idle...
  RETURNED_TO_IDLE: 90,	// HUB returned to idle; FINAL STATE (for successful remote unlock)

  ERROR_INITIATING_CLT: 100,			// Failure to send remote unlock request (CLIENT)
  ERROR_INITIATING_SRV: 101, 			// Failure to receive/process remote unlock request (SERVER)

  ERROR_RECEIVING_TIMEOUT_SRV: 200,		// Failure to advance from INITIATED state (SERVER)  aka "can not reach hub"
  ERROR_RECEIVING_TIMEOUT_CLT: 201,		// Failure to advance from INITIATED state (CLIENT)

  ERROR_AUTHING_WITH_SERVER_TIMEOUT_HUB: 300,	// Failure to advance from RECEIVED state (HUB)
  ERROR_AUTHING_WITH_SERVER_TIMEOUT_SRV: 301,	// Failure to advance from RECEIVED state (SERVER)
  ERROR_AUTHING_WITH_SERVER_TIMEOUT_CLT: 302,	// Failure to advance from RECEIVED state (CLIENT)

  ERROR_SEARCHING_TIMEOUT_HUB: 400,		// Failure to advance from AUTHD_BY_SERVER state (HUB) aka can not talk to hub: either the bluetooth is off on the hub phone or the hub phone is too far from the lock
  ERROR_SEARCHING_TIMEOUT_SRV: 401, 		// Failure to advance from AUTHD_BY_SERVER state (SERVER)
  ERROR_SEARCHING_TIMEOUT_CLT: 402,		// Failure to advance from AUTHD_BY_SERVER state (CLIENT)

  ERROR_CONNECTING_TIMEOUT_HUB: 500,		// Failure to advance from LOCK_FOUND state (HUB)
  ERROR_CONNECTING_TIMEOUT_SRV: 501,		// Failure to advance from LOCK_FOUND state (SERVER)
  ERROR_CONNECTING_TIMEOUT_CLT: 502,		// Failure to advance from LOCK_FOUND state (CLIENT)

  ERROR_AUTHING_WITH_LOCK_TIMEOUT_HUB: 600,	// Failure to advance from CONNECTED state (HUB)
  ERROR_AUTHING_WITH_LOCK_TIMEOUT_SRV: 601,	// Failure to advance from CONNECTED state (SERVER)
  ERROR_AUTHING_WITH_LOCK_TIMEOUT_CLT: 602,	// Failure to advance from CONNECTED state (CLIENT)

  ERROR_UNLOCKING_TIMEOUT_HUB: 700,		// Failure to advance from AUTHD_BY_LOCK state (HUB)
  ERROR_UNLOCKING_TIMEOUT_SRV: 701,		// Failure to advance from AUTHD_BY_LOCK state (SERVER)
  ERROR_UNLOCKING_TIMEOUT_CLT: 702,		// Failure to advance from AUTHD_BY_LOCK state (CLIENT)

  ERROR_RELOCKING_TIMEOUT_HUB: 800,   // Failure to advance from UNLOCKED state (HUB)
  ERROR_RELOCKING_TIMEOUT_SRV: 801,   // Failure to advance from UNLOCKED state (SERVER)
  ERROR_RELOCKING_TIMEOUT_CLT: 802,   // Failure to advance from UNLOCKED state (CLIENT)

  ERROR_RETURNING_TO_IDLE_TIMEOUT_HUB: 900,	// Failure to advance from RELOCKED state (HUB)
  ERROR_RETURNING_TO_IDLE_TIMEOUT_SRV: 901,	// Failure to advance from RELOCKED state (SERVER)
  ERROR_RETURNING_TO_IDLE_TIMEOUT_CLT: 902,	// Failure to advance from RELOCKED state (CLIENT)


  //////////////////////////////
  // STATUS CODES FOR LOCKING //
  //////////////////////////////

  REMOTELOCK_INITIATED: 11,         // INITIAL STATE
  REMOTELOCK_RECEIVED: 21,          // SERVER received remote LOCK request; SERVER creating new HubRequest...
  REMOTELOCK_AUTHD_BY_SERVER: 31,   // SERVER-HUB auth complete; HUB starting BLE scan for lock...
  REMOTELOCK_LOCK_FOUND: 41,        // HUB found lock; HUB connecting to lock...
  REMOTELOCK_CONNECTED: 51,         // HUB connected to lock; HUB starting hub-lock authentication...
  REMOTELOCK_AUTHD_BY_LOCK: 61,     // HUB-LOCK auth complete; HUB sending unlock command to lock...
  // Nothing for UNLOCKED because it doesn't make sense
  REMOTELOCK_LOCKED: 81,            // HUB received locked status from lock; HUB cleaning up resources and returning to idle...
  REMOTELOCK_LOCKED_ALREADY: 82,    // HUB received locked status from lock; LOCK is already locked...
  REMOTELOCK_RETURNED_TO_IDLE: 91,  // HUB returned to idle; FINAL STATE (for successful remote lock)

  ERROR_REMOTELOCK_INITIATING_SRV: 111,

  ERROR_REMOTELOCK_RECEIVING_TIMEOUT_SRV: 210,  // Failure to advance from REMOTELOCK_INITIATED state (SERVER)

  ERROR_REMOTELOCK_AUTHING_WITH_SERVER_TIMEOUT_HUB: 310,  // Failure to advance from REMOTELOCK_RECEIVED state (HUB)
  ERROR_REMOTELOCK_AUTHING_WITH_SERVER_TIMEOUT_SRV: 311,  // Failure to advance from REMOTELOCK_RECEIVED state (SERVER)

  ERROR_REMOTELOCK_SEARCHING_TIMEOUT_HUB: 410,  // Failure to advance from REMOTELOCK_AUTHD_BY_SERVER state (HUB)
  ERROR_REMOTELOCK_SEARCHING_TIMEOUT_SRV: 411,  // Failure to advance from REMOTELOCK_AUTHD_BY_SERVER state (SERVER)

  ERROR_REMOTELOCK_CONNECTING_TIMEOUT_HUB: 510, // Failure to advance from REMOTELOCK_LOCK_FOUND state (HUB)
  ERROR_REMOTELOCK_CONNECTING_TIMEOUT_SRV: 511, // Failure to advance from REMOTELOCK_LOCK_FOUND state (SERVER)

  ERROR_REMOTELOCK_AUTHING_WITH_LOCK_TIMEOUT_HUB: 610,  // Failure to advance from REMOTELOCK_CONNECTED state (HUB)
  ERROR_REMOTELOCK_AUTHING_WITH_LOCK_TIMEOUT_SRV: 611,  // Failure to advance from REMOTELOCK_CONNECTED state (SERVER)

  // Nothing for UNLOCKED (again) because it doesn't make sense

  ERROR_REMOTELOCK_LOCKING_TIMEOUT_HUB: 810,  // Failure to advance from REMOTELOCK_AUTHD_BY_LOCK state (HUB)
  ERROR_REMOTELOCK_LOCKING_TIMEOUT_SRV: 811,  // Failure to advance from REMOTELOCK_AUTHD_BY_LOCK state (SERVER)

  ERROR_REMOTELOCK_RETURNING_TO_IDLE_TIMEOUT_HUB: 910,  // Failure to advance from REMOTELOCK_LOCKED or REMOTELOCK_LOCKED_ALREADY states (HUB)
  ERROR_REMOTELOCK_RETURNING_TO_IDLE_TIMEOUT_SRV: 911   // Failure to advance from REMOTELOCK_LOCKED or REMOTELOCK_LOCKED_ALREADY states (SERVER)
}

// module.exports.InvitationStatus = {
//   PENDING: 0,
//   ACCEPTED: 1,
//   REJECTED: 2,
//   REMOVED: 3,
// };

// module.exports.KeyType = {
//   PHONE: 0,
//   SMS: 1,
// };

// module.exports.minimumLockSecurityLevel = {
//   pin: 0,
//   tap: 1,
//   auto: 2,
// };

// module.exports.Notifications = {
//   categories: {
//     notificationSubscription: 0,
//     notificationCategorySendKey: 1,
//     notificationCategoryRevokeKey: 2,
//     notificationCategoryModifyKey: 3,
//     notificationCategoryIftttService: 4,
//     notificationCategoryRequestRemoteUnlock: 5,
//   },
// };

// module.exports.SubscriptionType = {
//   targetedLockAndUser: 1,
//   targetedUserWildcardLock: 2,
//   targetedLockWildcardUser: 3,
//   wilcardLockWildcardUser: 4,
// };

// module.exports.RepeatType = {
//   /** No time restriction. */
//   None: 0,
//   /** A one time use key. */
//   OneTime: 1,
//   /** A weekly time restricted key. */
//   Weekly: 2,
// };

export const Roles = {
  USER: 0,
  ADMIN: 1,
  OWNER: 2,
};

export const GranularRoles = {
  User: 100,
  GroupUser: 125,
  GroupAdmin: 150,
  Administrator: 200,
  GroupOwner: 250,
  Owner: 300
}

// Used for targeting users who have unlocked or not today for instant messages
export const userScope = {
  HAVE_NOT_UNLOCKED_TODAY: -1, 
  ALL: 0, 
  DID_UNLOCK_TODAY: 1, 
}

export const WebSocketRequestIds = {
  REQUEST_ID_KEYRELATIONSHIP: 0,
  REQUEST_ID_USER: 1,
  REQUEST_ID_HUB_REQUEST: 2,
  REQUEST_ID_LOCK_STATE: 3,
  REQUEST_ID_NOTIFICATIONS: 100,
};

// module.exports.UnlockType = {
//   PHONE: 0,
//   REMOTE: 1,
//   SMS: 3,
//   BUSINESS_HOURS: 4,
// };

// module.exports.UserType = {
//   pendingUser: 0,
//   unlockedSMS: 1,
//   verifiedUser: 2,
//   apiUser: 3,
// };

export const LockType = {
  CYLINDER: 1,
  ELATCH: 2,
  VIRTUAL: 3,
  SOLO: 4,
  SOLO_V2: 5, // extended unlock capabilities & unlock time up to 30 seconds (like the ELATCH)
  CYLINDER_17: 100,
  ELATCH_17: 200,
  SOLO_17: 400,
  CYLINDER_V1_2: 120,
  ELATCH_V1_2: 220,
  SOLO_V1_2: 420
};

export const LockState = {
	LOCKED: 1,
	UNLOCKED: 2,
	UNREACHABLE: -1
}

// module.exports.apiKeyStatus = {
//   pendingApproval: 0,
//   rejected: 1,
//   suspended: 2,
//   approved: 3,
// };

// module.exports.getEnums = () => {
//   let enums = {};
//   for (let [v, k] of Object.entries(module.exports)) {
//     if (typeof k === 'object') enums[v] = k;
//   }
//   return enums;
// };
